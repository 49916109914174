<template>
  <div class="page">
    <div class="container next-container">
      <section class="section-container next-lottery">
        <h2 class="title">
          {{ $t('lotteryPage.head.title') }}
        </h2>

        <p class="subtitle">
          {{ $t('lotteryPage.head.subtitle', { from: begin, to: end }) }}
        </p>

        <div class="countdown">
          <span class="number">{{ counter.days }}</span> <span class="label">{{ $t('lotteryPage.countdown.days') }}</span> <span class="number">{{ counter.hours }}</span> <span class="label">{{ $t('lotteryPage.countdown.hours') }}</span> <span class="number">{{ counter.minutes }}</span> <span class="label">{{ $t('lotteryPage.countdown.minutes') }}</span>
        </div>
      </section>

      <section class="section-container last-winner">
        <template v-if="hasLastWinner">
          <div class="flex-x head">
            <div>
              <h2 class="title">
                {{ $t('lotteryPage.head.lastWinner') }}
              </h2>
              <p class="subtitle-date">
                Du {{ formatDate(new Date(lastWinners[0].startDate)) }} au {{ formatDate(new Date(lastWinners[0].endDate)) }}
              </p>
            </div>
            <button-link type="danger" :plain="false" class="el-button--default" :to="{ name: 'clubistProfile', params: { clubistId: lastWinners[0].clubist.id } }">
              {{ $t('lotteryPage.table.column.button') }}
            </button-link>
          </div>
        </template>

        <h2 v-else class="title">
          {{ $t('lotteryPage.head.lastWinner') }}
        </h2>

        <el-table v-if="hasLastWinner" :data="lastWinners">
          <el-table-column prop="clubist.lastName" :label="$t('tables.column.lastName')"></el-table-column>
          <el-table-column prop="clubist.firstName" :label="$t('tables.column.firstName')"></el-table-column>
          <el-table-column width="180" prop="clubist.cardNum" :label="$t('tables.column.cardNum')"></el-table-column>
          <el-table-column width="130" prop="clubist.phone" :label="$t('tables.column.phone')"></el-table-column>
          <el-table-column width="80" :label="''">
            <template>
              <div @click="openLastWinnerDetailsModal()">
                <Information />
              </div>
            </template>
          </el-table-column>
        </el-table>

        <p v-else-if="!isLoading" class="empty">
          - {{ $t('lotteryPage.table.empty') }} -
        </p>
      </section>
    </div>

    <section class="container section-container history">
      <header>
        <h2 class="title">
          {{ $t('lotteryPage.table.title') }}
        </h2>

        <p class="explain">
          {{ $t('lotteryPage.table.explain') }}
        </p>
      </header>

      <div class="search-container">
        <el-input
          :placeholder="$t('lotteryPage.table.search')"
          :clearable="true"
          v-model="inputSearch">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>

      <TableWinners @open-winner-details="openWinnerDetailsModal" :search="inputSearch" />
    </section>

    <LotteryWinnerDetails
      :details="winnerRow"
      :isOpen="isDetailsOpen"
      @close="isDetailsOpen = false"
    />
  </div>
</template>

<script>
import Information from '@/components/Information.vue'
import TableWinners from '@/components/TableWinners.vue'
import LotteryWinnerDetails from '@/modals/LotteryWinnerDetails.vue'
import Counter from '@/mixins/Counter.js'
import { getStartCurrentLotteryDate, getEndCurrentLotteryDate } from '@/pure/date'

export default {
  mixins: [ Counter ],
  components: {
    Information,
    TableWinners,
    LotteryWinnerDetails
  },
  data () {
    return {
      inputSearch: '',
      nextWinners: null,
      isLoading: false,
      winnerRow: null,
      isDetailsOpen: false,
      lastWinners: null
    }
  },
  computed: {
    hasLastWinner () {
      return this.lastWinners && this.lastWinners.length > 0
    },
    begin () {
      return getStartCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    },
    end () {
      return getEndCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    }
  },
  created () {
    this.isLoading = true

    this.$store.dispatch('lottery/getLastLotteries', { isConfirmed: true, count: 1 })
      .then(lotteries => {
        this.lastWinners = lotteries
      })
      .catch(error => this.$message.error(error.message))
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    openLastWinnerDetailsModal () {
      this.isDetailsOpen = true
      this.winnerRow = this.lastWinners[0]
    },

    openWinnerDetailsModal (row) {
      this.isDetailsOpen = true
      this.winnerRow = row
    },

    formatDate (date) {
      return date.toLocaleDateString('fr-FR')
    }
  }
}
</script>

<style lang="scss" scoped>
.next-container {
  margin-top: 20px;
  padding: 0;
  display: flex;

  .section-container {
    margin: 0;
  }
  .flex-x {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-button {
      margin: 0;
    }
  }
  .head {
    margin-left: 10px;
    margin-right: 10px;
  }
  .next-lottery {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .last-winner {
    margin-left: 25px;
    width: 62%;

    .el-table {
      ::v-deep th, ::v-deep td {
        border: 0 !important;
      }

      &:before {
        display: none;
      }
    }
  }
}
.history {
  margin-top: 30px;
  padding-bottom: 20px;
}
.title {
  text-align: center;
}
.subtitle-date {
  font-weight: 500;
  text-align: left;
  margin: -16px 0 0;
}
.subtitle {
  text-align: center;
  font-weight: 500;
}
.explain {
  text-align: center;
}

.search-container {
  margin: 20px 0 20px auto;
  width: 315px;
}

.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.head {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: center;
}
.el-menu-demo {
  display: inline-block
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
  margin: 30px 0;
}
.infos {
  margin-left: 2em;
}
.countdown {
  margin-top: 25px;
  text-align: center;
  .number {
    font-size: 20px;
  }
  .label {
    font-size: 14px;
  }
}
</style>

<template>
  <div ref="page" class="page search-page">
    <div class="container">
      <section class="section-title">
        <transition name="el-fade-in-linear">
          <el-alert
            v-if="returnAlert"
            type="warning"
            effect="dark"
            show-icon
            :title="returnAlert"
            :closable="false"
          ></el-alert>
        </transition>
        <el-row type="flex" justify="end">
          <el-button data-cy="create-clubist-open" @click="isModalClubistCreateOpen = true" type="success">Ajouter un clubiste</el-button>
        </el-row>
      </section>
      <section class="section-container form-container" v-loading="isLoading">
        <h1>Rechercher un clubiste</h1>
        <p class="info">Renseignez au moins un champ ou Nom + Prénom pour préciser votre recherche</p>
        <el-form :model="search" :rules="rules" ref="searchForm" label-position="top" class="inputs">
          <el-row type="flex" justify="space-between">
            <el-col :span="7">
              <el-form-item data-cy="last-name" label="Nom" prop="lastName">
                <el-input placeholder="Smith" maxlength="25" :clearable="true" @keyup.enter.native="submit" v-model="search.lastName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item data-cy="first-name" label="Prénom" prop="firstName">
                <el-input placeholder="John" maxlength="25" :clearable="true" @keyup.enter.native="submit" v-model="search.firstName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item data-cy="phone" label="N° de téléphone" prop="phone">
                <el-input minlength="12" maxlength="12" :clearable="true" show-word-limit placeholder="212XXXXXXXXX" @keyup.enter.native="submit" v-model="search.phone">
                  <!-- <template slot="prepend">212</template> -->
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="7" :offset="5">
              <el-form-item data-cy="club-card-num" label="N° de carte Club" prop="clubCardNum">
                <el-input minlength="19" maxlength="19" :clearable="true" show-word-limit placeholder="XXXXXXXXXXXXXXXXXXX" @keyup.enter.native="submit" v-model="search.clubCardNum"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7" :offset="2">
              <el-form-item data-cy="prepaid-card-num" label="N° de Card prépayée" prop="prepaidCardNum">
                <el-input minlength="6" maxlength="6" :clearable="true" show-word-limit placeholder="XXXXXX" @keyup.enter.native="submit" v-model="search.prepaidCardNum"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row data-cy="reset-search" type="flex" justify="center">
          <el-button @click="clean">vider les champs</el-button>
          <el-button type="danger" @click="submit">Rechercher</el-button>
        </el-row>
      </section>
      <section ref="results" class="section-container" v-if="list">
        <p class="info">
          {{ list.length }}
          <template v-if="list.length > 1">résultats trouvés</template>
          <template v-else>résultat trouvé</template>
        </p>
        <template v-if="list.length > 0">
          <el-table :data="clubistPart" empty-text="Pas de clubistes">
            <el-table-column label="Statut" width="70">
              <template slot-scope="scope">
                <Status :status="scope.row.statusID"></Status>
              </template>
            </el-table-column>
            <el-table-column prop="lastName" width="150" label="Nom"></el-table-column>
            <el-table-column prop="firstName" width="150" label="Prénom"></el-table-column>
            <el-table-column prop="cardNum" label="N° de carte Club"></el-table-column>
            <el-table-column prop="prepaidCardNum" label="N° de Card prépayée"></el-table-column>
            <el-table-column prop="phone" width="130" label="N° de téléphone"></el-table-column>
            <el-table-column align="right" width="140">
              <template slot-scope="scope">
                <button-link data-cy="clubist-link" type="danger" :to="{ name: 'clubistProfile', params: { clubistId: scope.row.id } }">
                  Voir le profil
                </button-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :current-page="currentPage"
            :total="list.length"
            :page-size="clubistDisplayCount"
            v-if="!isLoading"
            hide-on-single-page
            @current-change="num => currentPage = num"
            @prev-click="() => currentPage--"
            @next-click="() => currentPage++"
          ></el-pagination>
        </template>
      </section>
    </div>

    <ModalClubistCreate
      :isOpen="isModalClubistCreateOpen"
      @close="isModalClubistCreateOpen = false"
    />
  </div>
</template>

<script>
import anime from 'animejs'
import Status from '@/components/Status.vue'
import { phoneRule, clubCardNumRule, lastnameRule, firstnameRule, createRequired, totalCardNumRule } from '@/data/input-rules'
import ModalClubistCreate from '@/modals/ClubistCreate.vue'

export default {
  name: 'search',
  components: {
    Status,
    ModalClubistCreate
  },
  data () {
    return {
      isModalClubistCreateOpen: false,
      search: {
        lastName: this.$store.getters.searchLastName,
        firstName: this.$store.getters.searchFirstName,
        clubCardNum: this.$store.getters.searchClubCardNum,
        prepaidCardNum: this.$store.getters.searchPrepaidCardNum,
        phone: this.$store.getters.searchPhone
      },
      currentPage: 1,
      isLoading: false,
      clubistDisplayCount: 10,
      list: null
    }
  },
  computed: {
    clubistPart () {
      const min = (this.currentPage - 1) * this.clubistDisplayCount
      const max = min + this.clubistDisplayCount
      return this.list.slice(min, max)
    },
    returnAlert () {
      let message = ''
      return message
    },
    rules () {
      const forceNames = this.search.clubCardNum === '' && this.search.phone === '' && this.search.prepaidCardNum === ''
      return {
        clubCardNum: clubCardNumRule,
        prepaidCardNum: totalCardNumRule,
        phone: phoneRule,
        lastName: [
          ...lastnameRule,
          ...(forceNames ? [createRequired('nom')] : [])
        ],
        firstName: [
          ...firstnameRule,
          ...(forceNames ? [createRequired('prénom')] : [])
        ]
      }
    }
  },
  watch: {
    currentPage (current) {
      const pageCount = Math.ceil(this.list.length / this.clubistDisplayCount)
      this.currentPage = Math.max(Math.min(current, pageCount), 1)
    }
  },
  methods: {
    clean () {
      this.search.lastName = ''
      this.search.firstName = ''
      this.search.clubCardNum = ''
      this.search.prepaidCardNum = ''
      this.search.phone = ''
    },
    submit () {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.currentPage = 1
          this.list = []
          this.$store.dispatch('searchClubist', JSON.parse(JSON.stringify(this.search)))
            .then(list => {
              console.log(list)
              // clubCardNum
              this.list = list
              this.$nextTick(this.scrollToResults)
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        } else {
          return false
        }
      })
    },
    scrollToResults () {
      if (!this.$refs.page || !this.$refs.results) {
        return
      }

      const now = this.$refs.page.scrollTop
      const next = this.$refs.results.offsetTop
      const data = {
        y: now
      }

      anime({
        targets: data,
        y: next,
        duration: 500,
        easing: 'easeInCirc',
        update: () => {
          if (!this.$refs.page) {
            return
          }

          if (this.$refs.page.scroll) {
            this.$refs.page.scroll(0, data.y)
          } else {
            // IE11 fix
            this.$refs.page.scrollTop = data.y
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form {
  margin-bottom: 25px;
}
.el-table {
  margin: 50px 0;
}
.el-pagination {
  text-align: center;
}
.form-container {
  padding-left: 200px;
  padding-right: 200px;
}
</style>

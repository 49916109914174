<template>
  <el-dialog
    data-cy="create-clubist-modal"
    v-loading="isLoading"
    :visible="isOpen"
    title="Ajouter un clubiste"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row v-if="state === 1" class="left">
      <el-col :offset="4" :span="16" >
        <el-form
          :model="clubistInputs"
          ref="formCard"
          label-position="top"
          :rules="rules"
          @submit.native.prevent
        >
          <p>
            Veuillez renseigner le numéro de carte Club du Clubiste.
          </p>
          <el-form-item data-cy="club-card-num" prop="cardNum" label="N° de carte Club">
            <el-input minlength="19" maxlength="19" show-word-limit v-model="clubistInputs.cardNum"  @keyup.enter.native="submit"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-else class="left">
      <el-col :offset="3" :span="18" >
        <el-form
          :model="clubistInputs"
          ref="formClubist"
          :rules="rules"
          label-position="top"
          class="formClubist"
          @submit.native.prevent
        >
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="civility" prop="civility" label="Civilité">
                <el-radio-group v-model="clubistInputs.civility">
                  <el-radio label="female" border>Mme</el-radio>
                  <el-radio label="male" border>M.</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="last-name" prop="lastName" label="Nom">
                <el-input maxlength="25" v-model="clubistInputs.lastName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="first-name" prop="firstName" label="Prénom">
                <el-input maxlength="25" v-model="clubistInputs.firstName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="phone" prop="phone" label="N° de téléphone">
                <el-input minlength="12" maxlength="12" show-word-limit v-model="clubistInputs.phone" placeholder="212#########"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item prop="cardNum" label="N° de carte Club">
                <el-input minlength="19" maxlength="19" show-word-limit v-model="clubistInputs.cardNum" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="city" prop="city" label="Ville">
                <el-input maxlength="25" v-model="clubistInputs.city"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="birth-date" prop="birthDate" label="Date de naissance">
                <el-input type="text" v-model="clubistInputs.birthDate" maxlength="10" placeholder="dd/mm/yyyy" @keyup.enter.native="submit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="email" :rules="clubistInputs.isOptinEmail ? rules.emailIsOptin : rules.emailNoOptin" prop="email" label="Adresse email">
                <el-input v-model="clubistInputs.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="25">
            <el-col :span="24">
              <el-form-item data-cy="vehicle" prop="vehicle" label="Type de véhicule" class="items-inline">
                <el-radio-group v-model="clubistInputs.vehicle">
                  <el-radio :label="VEHICLES.CAR">Véhicule Léger</el-radio>
                  <el-radio :label="VEHICLES.TAXI">Taxi</el-radio>
                  <el-radio :label="VEHICLES.TRUCK">Poids Lourd</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <h2 class="title-contact switch center">Préférences de contact</h2>
          <p>Accepte être contacté par TotalEnergies Maroc à des fins d’enquêtes ou d’information et souhaite recevoir les offres commerciales :</p>
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="optin-email" prop="isOptinEmail" label="Par email" class="items-inline">
                <el-radio-group v-model="clubistInputs.isOptinEmail">
                  <el-radio :label="true">Oui</el-radio>
                  <el-radio :label="false">Non</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="optin-sms" prop="isOptinSms" label="Par SMS" class="items-inline">
                <el-radio-group v-model="clubistInputs.isOptinSms">
                  <el-radio :label="true">Oui</el-radio>
                  <el-radio :label="false">Non</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <p class="custom-label is-required">Langue pour recevoir mes communications email/SMS</p>
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="language" prop="language" label="">
                <el-radio-group v-model="clubistInputs.language">
                  <el-radio label="fr">Français</el-radio>
                  <el-radio label="ar">Arabe</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button data-cy="close" @click="$emit('close')">Annuler</el-button>
      <el-button data-cy="confirm" type="primary" @click="submit()">Confirmer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { phoneRule, emailRule, lastnameRule, firstnameRule, clubCardNumRule, totalCardNumRule, createRequired, birthDateFrRule, frDateToStdDate, formatMarocPhone, formatPartFrDate, cityRule } from '@/data/input-rules'

const VEHICLES = {
  CAR: 'car',
  TAXI: 'taxi',
  TRUCK: 'truck'
}

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      state: 3,
      VEHICLES: VEHICLES,

      rules: {
        civility: [
          createRequired('civilité')
        ],
        phone: [
          ...phoneRule,
          createRequired('téléphone')
        ],
        emailIsOptin: [
          createRequired('email'),
          ...emailRule
        ],
        emailNoOptin: [
          ...emailRule
        ],
        lastName: [
          ...lastnameRule,
          createRequired('nom')
        ],
        firstName: [
          ...firstnameRule,
          createRequired('prénom')
        ],
        cardNum: [
          ...clubCardNumRule,
          createRequired('numéro de carte')
        ],
        birthDate: [
          ...birthDateFrRule
        ],
        prepaidCardNum: [
          ...totalCardNumRule
        ],
        city: [
          ...cityRule
        ],
        isOptinEmail: [
          createRequired('optin email')
        ],
        isOptinSms: [
          createRequired('optin SMS')
        ],
        language: [
          createRequired('langage')
        ],
        vehicle: [
          createRequired('véhicule')
        ]
      },

      clubistInputs: { }
    }
  },
  watch: {
    'clubistInputs.birthDate': 'onDateChange',
    'clubistInputs.phone': 'onPhoneChange',
    isOpen: 'restart'
  },
  methods: {
    restart (refresh) {
      if (refresh) {
        this.state = 1
        this.clubistInputs = {
          civility: null,
          firstName: '',
          city: '',
          lastName: '',
          cardNum: null,
          prepaidCardNum: null,
          phone: '212',
          email: '',
          birthDate: '',
          vehicle: '',
          isOptinSms: null,
          isOptinEmail: null,
          language: null
        }
      }
    },
    onPhoneChange (value) {
      let newValue = formatMarocPhone(value)
      if (newValue !== value) {
        this.$nextTick(() => this.$set(this.clubistInputs, 'phone', newValue))
      }
    },
    onDateChange (value, last) {
      let newValue = formatPartFrDate(value, last)
      if (newValue !== value) {
        this.$nextTick(() => this.$set(this.clubistInputs, 'birthDate', newValue))
      }
    },
    submit () {
      if (this.state === 1) {
        this.submitCard()
      } else {
        this.submitClubist()
      }
    },
    submitCard () {
      this.$refs.formCard.validate((valid) => {
        if (valid) {
          this.isLoading = true
          const payload = {
            cardNum: this.clubistInputs.cardNum
          }
          this.$store.dispatch('getUnlinkedClubCard', payload)
            .then(clubist => {
              // Get vehicle data
              let vehicle = ''
              if (clubist.isCar) {
                vehicle = VEHICLES.CAR
              }

              if (clubist.isTruck) {
                vehicle = VEHICLES.TRUCK
              }

              if (clubist.isTaxi) {
                vehicle = VEHICLES.TAXI
              }

              this.clubistInputs = Object.assign({ }, this.clubistInputs, clubist, { vehicle })
              this.state = 2
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        } else {
          return false
        }
      })
    },
    submitClubist () {
      this.$refs.formClubist.validate((valid) => {
        if (valid) {
          this.isLoading = true

          const clubistData = Object.assign({}, this.clubistInputs)

          // Convert vehicle to taxi, truck or car data
          if (clubistData.vehicle) {
            clubistData.isCar = clubistData.vehicle === VEHICLES.CAR || clubistData.vehicle === VEHICLES.TAXI
            clubistData.isTaxi = clubistData.vehicle === VEHICLES.TAXI
            clubistData.isTruck = clubistData.vehicle === VEHICLES.TRUCK
            delete clubistData.vehicle
          }

          // Remove unusued keys
          Object.keys(clubistData).forEach(label => {
            if (clubistData[label] === null || clubistData[label] === '') {
              delete clubistData[label]
            }
          })

          // Remove unusued property
          delete clubistData.profile

          // Convert fr date to std date
          if (clubistData.birthDate) {
            clubistData.birthDate = frDateToStdDate(clubistData.birthDate)
          }

          this.$store.dispatch('createClubist', clubistData)
            .then(clubist => {
              this.$message({
                message: 'Le clubiste a bien été créé',
                type: 'success'
              })
              this.$emit('close')
              const clubistId = clubist.id
              this.$router.push({ name: 'clubistProfile', params: { clubistId } })
                .catch(error => console.error(error.message))
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title-contact {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 16px;
  color: $dark-grey;
}

.switch {
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}
</style>

<template>
  <div v-loading="isLoading" class="reset-pass-page page">
    <div class="container">
      <el-row type="flex" justify="center" class="row-logo">
        <el-row>
          <img src="@/assets/images/total-energies-logo-2x.png" alt width="180" height="144"/>
        </el-row>
      </el-row>
      <el-row type="flex" align="middle" justify="center">
        <el-col>
          <el-row  class="row-with-form align-center">
            <h1 class="marg-b">Réinitialisation de mot de passe</h1>
            <p class="marg-b">
              Vous avez demandé à réinitialiser votre mot de passe.<br>
              Veuillez en entrer un nouveau ci-dessous.
            </p>
            <el-form :model="user" :rules="rules" ref="loginForm" label-position="top">
              <el-form-item label="Nouveau mot de passe" prop="password">
                <el-input placeholder="Mot de passe" v-model="user.password" show-password @keyup.enter.native="submit"></el-input>
              </el-form-item>
              <div class="requirements">
                <p>Le mot de passe doit contenir au moins</p>
                <ul>
                  <li>8 caractères</li>
                  <li>1 chiffre</li>
                  <li>1 majuscule</li>
                  <li>1 minuscule</li>
                </ul>
              </div>
            </el-form>
            <el-row type="flex" justify="center">
              <el-button type="danger" @click="submit">Valider</el-button>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { passwordRule, createRequired } from '@/data/input-rules'

export default {
  name: 'login',
  data () {
    return {
      isLoading: false,
      user: {
        password: ''
      },
      rules: {
        password: [
          ...passwordRule,
          createRequired('mot de passe')
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          const data = {
            hash: this.$route.params.hash,
            password: this.user.password
          }
          this.$store.dispatch('updateUserPasswors', data)
            .then(() => {
              this.$router.push({ name: 'home' })
                .catch(error => console.error(error.message))
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-pass-page {
  display: block;
  background-color: transparent;
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
  .el-form {
    text-align: left;
    margin-bottom: 25px;
  }
  .el-button {
    margin-bottom: 25px;
    margin-top: 50px;
  }
}
.container {
  width: 640px;
}
.row-logo {
  margin-top: 2em;
  margin-bottom: 2em;
}
.row-with-form {
  max-width: 500px;
  margin: 0 auto;
}
.requirements {
  font-size: 14px;
  p {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  ul li::before {
    content: "✓";
    margin-right: 5px;
  }
}
.marg-b {
  margin-bottom: 1.5em;
}
</style>

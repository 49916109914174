<template>
  <div class="page">
    <div class="container next-container">
      <section class="next-lottery">
        <h2 class="title">
          Envoi de TotalEnergies Card
        </h2>
        <p class="subtitle">
          Clubistes ayant réalisés une commande de TotalEnergies Card
        </p>
      </section>
    </div>
    <section v-loading="isLoading" class="container">
      <TableFiles
        :list="list"
        :hasStatus="false"
        @click="onClick"
      />
    </section>
  </div>
</template>

<script>
import TableFiles from '@/components/TableFiles.vue'

export default {
  name: 'SendPrepaidCard',

  components: {
    TableFiles
  },

  data () {
    return {
      isLoading: false,
      listRaw: []
    }
  },

  computed: {
    list () {
      return this.listRaw.map(this.format)
    }
  },

  created () {
    this.update()
  },

  methods: {

    format (media) {
      const data = {
        id: media.id,
        date: media.date,
        name: media.file.name,
        actionButton: `<i class="el-icon-download file-icon"></i> Exporter`,
        actionMessage: null,
        actionData: null
      }

      if (!media.isDownloaded) {
        data.actionData = {
          file: media.file,
          category: 'PREPAID_CARD_EXPORT'
        }
      } else {
        data.actionData = {
          disabled: true
        }
      }

      return data
    },

    onClick ({ file, category }) {
      const options = {
        category,
        file
      }

      this.isLoading = true
      this.$store.dispatch('file/downloadFile', options)
        .then(() => this.$message({
          message: 'Le fichier ' + file.name + ' a bien été téléchargé',
          type: 'success'
        }))
        .catch(error => {
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
          this.update()
        })
    },

    update () {
      this.isLoading = true
      this.$store.dispatch('media/getMedia', { category: 'PREPAID_CARD_EXPORT' })
        .then(list => {
          this.listRaw = list
        })
        .catch(error => {
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    dateToStr (time) {
      const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },

    dateTimeToStr (time) {
      const date = new Date(time)
      return [date.getHours(), date.getMinutes()].join(':')
    }
  }
}
</script>

<style lang="scss" scoped>
.next-container {
  margin-top: 20px;
  padding: 0;
  display: flex;

  .section-container {
    margin: 0;
  }

  .head {
    margin-left: 10px;
    margin-right: 10px;
  }
  .next-lottery {
    flex: 1;
  }
  .next-winner {
    margin-left: 25px;
    width: 58%;

    .el-table {
      ::v-deep th, ::v-deep td {
        border: 0 !important;
      }

      &:before {
        display: none;
      }
    }
  }
}
.title {
  text-align: center;
  color: #000;
}
.subtitle {
  text-align: center;
  font-weight: 500;
}
.explain {
  text-align: center;
}

.search-container {
  margin: 20px 0 20px auto;
  width: 315px;
}

.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.head {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.el-menu-demo {
  display: inline-block
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
  margin: 30px 0;
}
.infos {
  margin-left: 2em;
}
</style>

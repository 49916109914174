<template>
  <el-dialog
    :visible="isOpen"
    title="Détail de la transaction gagnante"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="525px"
  >
    <div v-if="details" class="details-container">
      <div class="left">
        <p>ID Transaction</p>
        <p>Date/Heure</p>
        <p>Nature</p>
        <p>Gamme</p>
        <p>Emballage (L)</p>
        <p>Montant (dhs)</p>
        <p>Station</p>
        <p>Ville</p>
        <p>N° de Carte Club</p>
      </div>

      <div class="right">
        <p>{{details.id}}</p>
        <p>{{formatDate(details.date)}} {{formatTime(details.date)}}</p>
        <p>{{details.nature}}</p>
        <p>{{details.gamme}}</p>
        <p>{{details.liters}}</p>
        <p>{{details.dhs}}</p>
        <p>{{details.station}}</p>
        <p>{{details.city}}</p>
        <p>{{details.clubist.cardNum}}</p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LotteryWinnerDetails',

  props: {
    details: {
      type: Object,
      default: () => {}
    },

    isOpen: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    formatDate (date) {
      return new Date(date).toLocaleDateString('fr-FR', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      })
    },

    formatTime (date) {
      return new Date(date).toLocaleTimeString('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.details-container {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 50px;

  .left {
    width: 150px;

    p {
      display: flex;
      align-items: center;
      height: 40px;
      margin: 0;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
    }
  }

  .right {
    width: calc(100% - 150px);

    p {
      display: flex;
      align-items: center;
      height: 40px;
      margin: 0;
      font-weight: normal;
      font-size: 16px;
      text-align: left;
    }
  }
}
</style>

<template>
  <div v-loading="isLoading" class="list">
    <template v-if="list.length > 0" class="list">
      <el-table :data="clubistPart">
        <el-table-column width="200" :label="$t('lotteryPage.table.column.period.label')">
          <template slot-scope="scope">
            {{ $t('lotteryPage.table.column.period.value', { from: dateToStr(scope.row.startDate), to: dateToStr(scope.row.endDate) }) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('lotteryPage.table.column.date')">
          <template slot-scope="scope">
            {{ dateToStr(scope.row.drawDate) }}
          </template>
        </el-table-column>
        <el-table-column prop="clubist.lastName" :label="$t('tables.column.lastName')"></el-table-column>
        <el-table-column prop="clubist.firstName" :label="$t('tables.column.firstName')"></el-table-column>
        <el-table-column prop="clubist.cardNum" width="200" :label="$t('tables.column.cardNum')"></el-table-column>
        <el-table-column :label="$t('tables.column.detail')">
          <template slot-scope="scope">
            <div @click="$emit('open-winner-details', scope.row)">
              <Information />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="scope">
            <button-link v-if="scope.row.clubist.id" type="danger" :plain="true" class="el-button--default" :to="{ name: 'clubistProfile', params: { clubistId: scope.row.clubist.id } }">
              {{ $t('lotteryPage.table.column.button') }}
            </button-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="list.length"
        :page-size="clubistDisplayCount"
        v-if="!isLoading"
        hide-on-single-page
        @current-change="num => currentPage = num"
        @prev-click="() => currentPage--"
        @next-click="() => currentPage++"
        class="pagination"
      ></el-pagination>
    </template>

    <p v-else-if="!isLoading" class="empty">
      - {{ $t('lotteryPage.table.empty') }} -
    </p>
  </div>
</template>

<script>
import Information from './Information.vue'

export default {
  components: { Information },

  props: {
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      currentPage: 1,
      clubistDisplayCount: 5,
      listAll: []
    }
  },
  computed: {
    list () {
      // clean name or card num
      const clean = data => (data || '').toLowerCase()

      // use multi keywords for search
      const searches = (this.search || '').split(' ')

      // If no search, return all list
      if (searches.length === 1 && searches[0] === '') {
        return [...this.listAll]
      } else {
        let list = this.listAll

        // Loop on all search keywords
        searches.forEach(search => {
          list = list.filter(winner => {
            if (search.length === 1 && search[0] === '') {
              return true
            } else {
              return clean(winner.clubist.firstName).indexOf(clean(search)) > -1 ||
                clean(winner.clubist.lastName).indexOf(clean(search)) > -1 ||
                clean(winner.clubist.cardNum).indexOf(clean(search)) > -1
            }
          })
        })
        return list
      }
    },
    clubistPart () {
      const min = (this.currentPage - 1) * this.clubistDisplayCount
      const max = min + this.clubistDisplayCount
      return this.list.slice(min, max)
    }
  },
  watch: {
    search () {
      this.currentPage = 1
    }
  },
  created () {
    this.isLoading = true
    this.$store.dispatch('lottery/getLotteries')
      .then(winners => {
        this.listAll = winners
      })
      .catch(error => this.$message.error(error.message))
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    dateToStr (time) {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return new Date(time).toLocaleDateString('fr-FR', options)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.pagination {
  margin: 20px 0 0;
}
</style>

<template>
  <div class="error-page page">
    <div class="container">
      <h1 class="title">Page non trouvée</h1>
      <p class="text">Il semblerait que cette route n'existe pas.</p>
      <button-link type="danger" :to="{ name: 'home' }" class="button">
        Se connecter
      </button-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error404'
}
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: transparent;
  text-align: center;
}
.title {
  font-size: 30px;
  margin-bottom: 1em;
}
.text {
  margin-bottom: 2em;
}
.button {
  margin-bottom: 10em;
}
</style>
